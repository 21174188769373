/*Z-INDEX*/
// z2 = titres, tirets;
// z3: polaroids;
// z4: ticket;
// z6: navbar full;

@font-face {
  font-family: 'Avenir';
  src: url('./assets/fonts/Avenir.ttc') format('ttc');
  font-weight: normal; /* Poids de la police */
}
@import url("https://use.typekit.net/fgw4hmb.css");

*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  cursor: url('./assets/Elements/icons/logo/cursor.png') 5 5, auto;
}

:root {
  /*FONTS*/
  --font-primary: "source-serif-4-subhead", serif;
  --font-secondary: 'Avenir', serif;

  /*WEIGHT*/
  --normal: normal;
  --bold: bold;
  --italic:italic;
  --weight-heavy: 800;  /*heavy*/
  --weight-bold: 700;/*bold*/
  --weight-semibold: 600; /*semibold*/
  --weight-book: 400; /*semibold*/
   /*SIZE*/
   --Titre:4.8rem;
   --Titre-lh:5.5rem;
   --TitreMobile: 2.6rem;
   --Titre-lhMobile:3.6rem;
   --SousTitre:3rem;
   --STitre-lh:4.5rem;
   --SousTitreMobile: 2.5rem;
   --SousTitre-lhMobile: 3rem;



   --TexteNarratif:4rem;
   --TexteNarratif-lineheight: 4.5rem;
   --TexteNarratifMobile: 2.5rem;
   --TexteNarratiflhMobile: 3rem;
   --Corpsdetexte: 1.3rem; /*semibold*/
   --footerTitre-size: 1rem;
   --Menu: 3rem;
   --Nav: 1rem;


  // --nav-size : 15pt;
  // --sous-nav-size: 12pt;
  // --nav-fenetre-size:47px;
  // --nav-fenetre-item-size:33px;
  // --nav-fenetre-pied-size:240px;

  --MenuTitre-size:76px;
  --MenuCorpsdeTexte-size: 27px;
  --MenuNumerotation-size: 40px;
  --FormulaireLabel-size: 26.5px;
  --FormulairePlaceholder-size: 26.5px;
  /* COULEURS*/
  --primary-color:#335956;
  --secondary-color:#81B795;
  --white:#ffff;
  --footer: #ffffffa9;
  --ticket-bg:#C5AE4B;
  --light: rgba(255, 247, 232, 0.48);
  --FormulairePlaceholder-color: #E6E3E3;
  --FormulaireLabel-color: #3D5269;
}

.App{
  overflow: hidden;
}
h1, h2{
  font-family: var(--font-primary);
  font-size: var(--Titre);
  font-weight: var(--weight-bold);
  font-style: var(--italic);
  line-height: var(--Titre-lh);
  color: white;
  text-align: center;
}
h3{
  font-family: var(--font-primary);
  font-size: var(--SousTitre);
  font-weight: var(--weight-bold);
  line-height: var(--STitre-lh);
  color: white;
  text-align: center;
}
.emphase{
  font-size: calc(var(--SousTitre)/1.1);
  line-height: calc(var(--STitre-lh)/1.4);
  margin-bottom: 12px
}
.titres{
  position: absolute;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  margin-top: 30px;
  z-index: 4;
}
.TexteNarratif{
  font-family: var(--font-primary);
  font-size: var(--TexteNarratif); //60pt
  font-weight: var(--weight-bold);
  line-height: var(--TexteNarratif-lineheight);
  text-align: center;
  width: 70vw;
}
  // width:70vw!important;
p{
  font-family: var(--font-primary);
  font-size: var(--Corpsdetexte);
  font-weight: var(--weight-semibold);
  color: white;
  text-align: center;
}

.film{
  position: relative;
  background-color: var(--primary-color);
}
.tiret-hp{
  position: absolute;
  z-index: 2;
  top: 97vh;
  width: 50vw;
  height: 40px;
  border-right: 3px solid white;
}
#titre-video{
  position:absolute;
  top: 68%;
  left: 0;
  right: 0;
  z-index: 2;
}
.background-video {
  width: 100%;
  height: 100vh;
  object-fit: cover;
  & video{
    width: 100%;
    // height: 100%;
    & source{
      width: 100%;
      height: 100%;
    }
  }
}
.mobile{
  display: none;
}
.section{
  position: relative;
  background-color: var(--primary-color);
  padding: 1rem;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  padding-top: 20vh;
}


/* Coordonnées Roue*/
.Conteneur{
  position:absolute;
  top: 63%;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  width: 150px;
  height: 40vh;
  z-index: 2;
}
.logo-roue {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border: 1.5px solid rgb(255, 255, 255);
  border-radius: 100px;
  padding: 15px;
}
.logo-roue img{
  width: 2rem;
}
text {
  font-size: .9rem;
}
.Conteneur svg{
  z-index: 1000;
  color: white;
}
.rotate-svg {
  font-family: var(--font-primary);
  animation: rotate 8s linear infinite;
}
text{
  color: white;
}
@keyframes rotate {
  from {transform: rotate(0deg);}
  to {transform: rotate(360deg);}
}
.pleinpot{
  width: 100vw;
  height: 70vh;
  object-fit: cover;
  margin-top: 2rem ;
}
#map{
  // margin-left: 2rem;
  position: relative;
  z-index: 0;
  width: 100vw;
  height: 70vh;
  object-fit: cover;
  margin-top: 2rem ;
}
.leaflet-container {
  width: 100%;
  height: 100%;
  pointer-events: none;
  // border-radius: 30px;
  z-index: 0;
}
.leaflet-bottom{
  display: none;
}

#polamap{
  width: 11rem;
  height: 14rem;
  object-fit: cover;
  border: .5rem white solid;
  position: absolute;
  left: 87%;
  top: 65%;
  transform: rotate(5deg);
}


/* FOOTER */
.grid-footer {
  display: grid;
  grid-template-columns: repeat(4,1fr);
  grid-template-rows: repeat(2, 1fr);
  grid-column-gap: 1rem;
  grid-row-gap: 1rem;
   & img{
    width: 30rem;
   }
   & p{
    font-family: var(--font-secondary);
   }
}  
.grid-footer-logo { 
    grid-area: 1 / 1 / 2 / 3; 
    padding: 1rem;
}
.grid-footer-adress { 
    grid-area: 1 / 3 / 2 / 5; 
    padding: 1rem;
    display: flex;
    flex-wrap: wrap;
    justify-content: end;
    align-items: center;
    & p{
      text-align: end;
    }
}
.grid-footer-item1 { 
    display: flex;
    flex-direction: column;    
    justify-content: center;
    align-items: center;
    gap: 1rem;

    & img{
      width: 3.5rem;
    } 
    & p{
      font-family: var(--font-secondary);
      color: white;
      text-align: center;
    }
}
.grid-footer-item1:nth-child(1) { grid-area: 2 / 1 / 3 / 2; }
.grid-footer-item1:nth-child(2) { grid-area: 2 / 2 / 3 / 3; }
.grid-footer-item1:nth-child(3) { grid-area: 2 / 3 / 3 / 4; }
.grid-footer-item1:last-of-type { grid-area: 2 / 4 / 3 / 5; }

.footer-div{
  background-color: #81B795;
  padding: 0 1rem 3rem 1rem;
  position: relative;
}

/* FOOTER FIXED */
.footer-fixed-grid{
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      grid-template-rows: 15vh;
      grid-column-gap: .5rem;
      grid-row-gap: .5rem;
      margin-bottom: 2 0 2 0rem;
}
.footer-fixed-grid-item{
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
    text-align: center;
    justify-content: center;
    align-items: center;
    & img {
      width: 2rem;
    }
    & p{
      font-family: var(--font-secondary);
      color: white;
      font-size: calc(var(--Corpsdetexte)/1.2);
    }
}
/*FOOTER - METHODE MAP*/
#footer-croquis {
    width: 50%;
    height: 100%;
    position: absolute;
    bottom: 2%;
    left: 50%;
    opacity: 0.1;
}
#phone-screen{
  width: 9rem!important;
}



/*NAVBAR*/
.navbar{
  position:fixed;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100vw;
  padding: 1.5rem;
  z-index:5;
  background-color: var(--primary-color);
}
.navbar img{
  width: 13rem;
  height: auto;
}
.navbar-menu img{
  width : 3rem
}
.navbar-full{
    display: none;
    z-index: 6;  
}
.open{
    position: fixed;
    width: 100vw;
    height: 100vh;
    left: 0;
    right: 0;
    top:0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-image: url('./assets/Elements/images/bg.png');
    background-size: cover;
    opacity: 1;
    z-index: 6;  
    text-align: center;
  & img{
    width: 50px;
    opacity: 0.2;
  }
}
.navbar-croix img{
  position: absolute;
  top : 2vh;
  left: 97vw;
  opacity: 1;

}
#logo-navbar{
  width: 30vw!important;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  opacity: 1;
  z-index: 6;  
}
.navbar-full-items{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 2rem;
  margin-bottom: 5rem;
  z-index: 6;  
  & a p{
    font-family: var(--font-primary)!important;
    font-size: var(--Menu)!important;
    font-weight: var(--weight-semibold)!important;
    align-items: center;
    opacity: 0; /* Masquer initialement les titres */
    transition: opacity 0.5s, transform 0.5s; /* Transition douce */
  }
  & a p:hover{
    font-family: var(--font-primary)!important;
    font-size: var(--Menu)!important;
    font-weight: var(--weight-semibold)!important;
    align-items: center;
    color: #81B795;
  }
}
.navbar-full-items2{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 2rem;
  width: 50px!important;
  z-index: 6;  
}
@keyframes fadeInUp {
  from {
    opacity: 0; /* Commencer avec une opacité de 0 */
    transform: translateY(20px); /* Commencer en bas */
  }
  to {
    opacity: 1; /* Arriver à une opacité de 1 */
    transform: translateY(0); /* Arriver à la position normale */
  }
}
/* Appliquer l'animation avec des délais différents pour chaque élément */
#evenement{
  animation: fadeInUp 0.5s ease 0.3s forwards!important; /* Délai de départ pour le premier élément */
}
#gites{
  animation: fadeInUp 0.5s ease 0.8s forwards!important; /* Délai de départ pour le deuxième élément */
}
#manade{
  animation: fadeInUp 0.5s ease 1.3s forwards!important; /* Délai de départ pour le troisième élément */
}
#contact{
  animation: fadeInUp 0.5s ease 2s forwards!important; /* Délai de départ pour le troisième élément */
}
.navbar-menu{
  display: flex;
  justify-content: start;
}





/////////////////// MENU ANIME///////////////////////
.grid-container {
  display: flex;
  gap: .5rem;
  padding: 20px;
  overflow-x: hidden;
  position: relative;
  width: 100vw;
  height: auto;
  object-fit: cover;
  margin-top: 2rem ;
}
.grid-item {
flex: 1; // 
position: relative;
overflow: hidden;
display: flex;
flex-direction: column;
gap: 1rem;
justify-content: center;
align-items: center;
width: 30vw;
height: 70vh;
transition: flex 0.3s ease-in-out, transform 0.3s ease-in-out; /* Ajout de la transition sur la propriété flex et transform */
}
.grid-item:hover {
flex-grow: 4; // élargit homothetiquement la div survolée
transition: opacity 0.3s ease-in-out, transform 0.3s ease-in-out;  
}
.grid-item:hover .grid-id,
.grid-item:hover .grid-title h3,
.grid-item:hover .grid-text {
  opacity: 1;
}
.grid-item img {
max-width: 100%;
}
.grid-title h3{
color: #fff;
font-family: var(--font-primary);
font-size: var(--MenuTitre-size);
color: #fff;
font-style: italic;
text-align: center;
opacity:0;
}  
.grid-text {
  color: #fff;
  font-family: var(--font-primary);
  font-size: var(--MenuCorpsdeTexte-size);
  text-align: center;
  opacity:0;
  & p {
    margin: 0!important;
  }
}
.grid-id{
  color: #fff;
  align-items: center;
  font-family: var(--font-primary);
  font-size: var(--MenuNumerotation-size);
  font-style: italic;
  text-align: center;
  opacity:0;
  border: 2px solid white;
  padding: 2px;
  border-radius: 80%;
  width: 200px;
  height: 60px;
  margin-top:1rem
}




/*TICKET STICKY*/
.ticket{
  position: fixed;
  background-image: url('./assets/Elements/ticket/ticket-bg.png');
  z-index: 4;
  top:12rem;
  transform: translateX(70%);
  right: 0;
  display: flex;
  flex-wrap: wrap;
  gap:1.5rem;
  justify-content: center;
  align-items: center;
  width: 574px; // 2.5 taille initiale
  height: 142px; // 2.5 taille initiale
  color: var(--ticket-bg);
  transition: transform 0.3s ease;
  }
  .liens img{
   width: 1.5rem;
  }
  .liens-items{
  display: flex;
  flex-wrap: wrap;
  gap: 3px;
  width: 350px;
  // margin-top: .3rem;
  margin-left: .5rem;
  }
  #p-ticket{
    font-family: var(--font-secondary);
    font-weight: var(--weight-heavy);
    font-size: 10px;
    color:var(--ticket-bg);
    text-transform: uppercase;
  }
  .liens-item{
    display: flex;
    flex-wrap: wrap;
    gap: .5rem;
    align-items: center;
    border: 1.5px solid var(--ticket-bg);
    border-radius: 60px;
    padding: .2rem .5rem;
    width: fit-content;
    font-family: var(--font-secondary);
  }                         
  .ticket-logo{
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 150px;
  }
  .ticket-logo p{
    font-family: var(--font-primary);
    font-weight: var(--weight-bold);
    font-style: var(--italic);
    font-size: 1rem;
    color: var(--ticket-bg);
  }
  .ticket-logo img{
    width: 3.5rem;
  }
  .ticket:hover{
    background-image: url('./assets/Elements/ticket/ticket-bg.png');
    transform: translateX(0%);
  }




/* SVG TRANSPARENT */
#svgBEC{
  position: absolute;
  width: 150px;
  height: auto;
  margin-top: 1px;
  left: 85vw;
  pointer-events: none;
  & img{
    width: 80%;
  }
  opacity: 1; /* Définit l'opacité initiale à 0 */
  transition: opacity 3s ease; /* Ajoute une transition de 1 seconde avec une accélération douce */
}
#svg-gardian-top{
  position: absolute;
  width: 230px;
  height: auto;
  bottom: 80vh;
  pointer-events: none;
  & img{
    width: 80%;
  }
  opacity: 1; /* Définit l'opacité initiale à 0 */
  transition: opacity 3s ease; /* Ajoute une transition de 1 seconde avec une accélération douce */
}
#svg-gardian-bottom{
  position: absolute;
  width: 230px;
  height: auto;
  top: 80vh;
  left: 85vw;
  pointer-events: none;
  & img{
    width: 80%;
  }
  opacity: 1; /* Définit l'opacité initiale à 0 */
  transition: opacity 3s ease; /* Ajoute une transition de 1 seconde avec une accélération douce */  
}
#svg-top{
  opacity: 0; /* Définit l'opacité initiale à 0 */
  transition: opacity 3s ease; /* Ajoute une transition de 1 seconde avec une accélération douce */
  position: absolute;
  top:10;
  pointer-events: none;
}
#svg-bottom{
  opacity: 0; /* Définit l'opacité initiale à 0 */
  transition: opacity 3s ease; /* Ajoute une transition de 1 seconde avec une accélération douce */
  position: absolute;
  pointer-events: none;
}
/*SLIDE-IN ANIMATION*/
.visible {
  opacity: 1;
  transition: opacity 0.5s ease; /* Ajoutez une transition pour une animation douce */
}
@keyframes slideInFromLeft {
  from {
      transform: translateX(-100%);
      opacity: 0;
  }
  to {
      transform: translateX(0);
      opacity: 1;
  }
}
@keyframes slideInFromRight {
  from {
      transform: translateX(100%);
      opacity: 0;
  }
  to {
      transform: translateX(0);
      opacity: 1;
  }
}
@keyframes slideInFromBottom {
  from {
      transform: translateY(100%);
      opacity: 0;
  }
  to {
      transform: translateY(0);
      opacity: 1;
  }
}



/* LINK DECORATIONS & GLOBAL ELEMENTS*/
a{
  text-decoration: none;
}
.btn{
  font-family: 'Avenir', serif;
  font-weight: 300;  /*heavy*/
  font-size: 33px;
  border-radius: 15px;
  padding: 10px;
}
.pastille{
  border-radius: 52px;
  padding: 21px;
  border : 1px solid white;
}



/* SVG ROTATION*/
.logo{
  position: absolute;
  left:37vw;
}
.logo img{
  width:30rem
}
.coordonnees{
  position: absolute;
  z-index:2;
  left: 44.8vw;
  bottom: 11vh;
  animation: rotateCoordinates 5s linear infinite;
}
.coordonnees img{
  width: 10vw;
}
@keyframes rotateCoordinates {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.section-svg{
  position: absolute;
  z-index: 100000;
  width: 100vw;
  height: 100vh;
  pointer-events: none;
}



/* QUEL COMPOSANT ??*/
.imageScroll{
  position: absolute;
  z-index: 2;
  overflow: hidden;
  width: 100vw;
  height: 90px;
  top : 80%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 3rem;
}
.bg-img{
  width: 320px;
  padding: 150px;
}
.imageScroll p{
  font-family: var(--font-primary);
  font-style: var(--italic);
  font-weight: var(--weight-bold);
  font-size:50px;
  color: var(--white);
  text-align: center;
}




/*EVENEMENTS*/

/*GRILLE POUR 4*/
.grid4 {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(2, 1fr);
  grid-column-gap: 20px;
  grid-row-gap: 20px;
  // rajouter pour marges et tailles différentes des blocs
  & h3{
    margin-top: 0;
  }
  & img{
    width: 600px;
    border: 10px white solid;
    height: 350px;
    object-fit: cover;
    margin: 0;
    box-shadow:
  3.1px 2.2px 9.4px rgba(0, 0, 0, 0.005),
  7.7px 5.5px 22.5px rgba(0, 0, 0, 0.006),
  14.9px 10.7px 42.3px rgba(0, 0, 0, 0.008),
  26.9px 19.3px 75.5px rgba(0, 0, 0, 0.009),
  50.7px 36.3px 141.2px rgba(0, 0, 0, 0.012),
  120px 86px 338px rgba(0, 0, 0, 0.02)
;
  }
}
.grid4-img:first-of-type{
    animation: slideInFromRight 1s ease;
    animation-iteration-count:  wipe-enter 1s infinite;
}
.grid4-img:nth-child(3) {
  animation: slideInFromLeft 1s ease;
  animation-iteration-count:  wipe-enter 1s infinite;
}
.grid4-img:last-child {
  animation: slideInFromBottom 1s ease;
  animation-iteration-count:  wipe-enter 1s infinite;
}
.grid4-description{
  display: flex;
  flex-direction: column;
  width : 600px;
  padding:.5rem;
  justify-content: center;
  align-items: center;
  text-align: center;
  gap: 1rem;
  & h3, p{
    position: relative
  }
}
.grid4-img-col2{
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  & img{
    width: calc(600px/2.07);
    border: 10px white solid;
    height: 350px;
    object-fit: cover;
    margin: 0;
    box-shadow:
  3.1px 2.2px 9.4px rgba(0, 0, 0, 0.005),
  7.7px 5.5px 22.5px rgba(0, 0, 0, 0.006),
  14.9px 10.7px 42.3px rgba(0, 0, 0, 0.008),
  26.9px 19.3px 75.5px rgba(0, 0, 0, 0.009),
  50.7px 36.3px 141.2px rgba(0, 0, 0, 0.012),
  120px 86px 338px rgba(0, 0, 0, 0.02)
;

  }
}


/*GRILLE POUR 5*/
.grid5 {
    display: grid;
    grid-template-columns: 1fr 2fr;
    grid-template-rows: repeat(2, 1fr);
    grid-column-gap: 30px;
    grid-row-gap: 30px;
}

/* GRILLE PILLS*/
.center{
  display: flex;
  justify-content: center ;
}
.grid3-pills{
  display: flex;
  flex-wrap: wrap;
  gap: .5em;
  margin-top: 1rem;
  justify-content: center;
  align-items: center;
  width: 70vw;
}
.grid3-pills-item{
  border: 1px solid white;
  border-radius: 40px;
  padding: .8rem;
  width: fit-content;
  height: fit-content;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: .5rem;
  & p{
    font: var(--font-secondary);
    font-size: 1em;
    font-weight: var(--sous-nav-weight);
    margin:0;
  }
  & img{
    margin: 0;
    width: 15px;
  }
}
.grid4-pills{
  display: flex;
  flex-wrap: wrap;
  gap: .5em;
  margin-top: 1rem;
  justify-content: center;
  align-items: center;
  width: 600px;
}
.grid4-pills-item{
  border: 1px solid white;
  border-radius: 40px;
  padding: .8rem;
  width: fit-content;
  height: fit-content;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: .5rem;
  & p{
    font: var(--font-secondary);
    font-size: 1em;
    font-weight: var(--sous-nav-weight);
  }
  & img{
    width: 1.2em;
    height: auto;
    border: none;
  }
}

/*GRILLE MANADE*/
.grid-manade{
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(2, 50vh);
  grid-column-gap: 1rem;
  grid-row-gap: 1rem;
}
.grid-manade1{
  width: 100%;
  height: 90%;
  margin-bottom: 10%;
  object-fit: cover;
  border: 10px white solid;
  box-shadow:
3.1px 2.2px 9.4px rgba(0, 0, 0, 0.005),
7.7px 5.5px 22.5px rgba(0, 0, 0, 0.006),
14.9px 10.7px 42.3px rgba(0, 0, 0, 0.008),
26.9px 19.3px 75.5px rgba(0, 0, 0, 0.009),
50.7px 36.3px 141.2px rgba(0, 0, 0, 0.012),
120px 86px 338px rgba(0, 0, 0, 0.02)
;
}
.grid-manade2{
  width: 100%;
  height: 100%;
  margin-top: 10%;
  object-fit: cover;
  border: 10px white solid;
  box-shadow:
3.1px 2.2px 9.4px rgba(0, 0, 0, 0.005),
7.7px 5.5px 22.5px rgba(0, 0, 0, 0.006),
14.9px 10.7px 42.3px rgba(0, 0, 0, 0.008),
26.9px 19.3px 75.5px rgba(0, 0, 0, 0.009),
50.7px 36.3px 141.2px rgba(0, 0, 0, 0.012),
120px 86px 338px rgba(0, 0, 0, 0.02)
;

}
.grid-manade-logo{
  width: 30%;
  display: flex;
  justify-content: center;
}
.grid-manade-text{
  width: 100%;
  height: 30%;
  margin-bottom: 30%;
  font-family: var(--font-primary);
  font-size: var(--TexteNarratif);
  line-height: var(--TexteNarratif-lineheight);
  font-weight: var(--weight-bold);
  // grid-area: 2 / 1 / 3 / 3;

}  
.grid-manade-svg{
  margin-top: 40px;
  width: 10%;
  height: auto;
}

#trident{
  width:300px;
  height: auto;
  margin: 40px 0 0 65%;
  transform: rotate(-10deg); 
}
#logobec{
  width:100%;
  display: flex;
  justify-content: center;
  & img{
    // width: fit-content;
    height: auto;
  }
}

/* MANADE SVG ANIMATION*/
.svg-item{
  position: absolute;
  animation: fadeIn 1s forwards;
  opacity: 0; /* Assurez-vous que l'élément est initialement transparent */
  pointer-events: none;
}
@keyframes fadeIn {
  from {
    opacity: 0; /* Opacité initiale de 0 (transparent) */
  }
  to {
    opacity: 1; /* Opacité finale de 1 (complètement opaque) */
  }
}

/* CAROUSEL VERTICAL*/
.grid-col-carousel{
    display: grid;
    grid-template-columns: 2fr 1fr;
    grid-template-rows: 1fr;
    width: 90vw;
    height: 100%;
    margin-top: 2rem;
    gap: 10px;
}
.carousel{
    display: flex;
}
#btn-fleche{
  border: none;
  background-color: #ffffff00;
}
#fleche{
  width: 2rem;
  height: auto;
  border: none;
}
.carousel-item{
    display: flex;
    flex-direction: column;
    gap:1.5rem;
    justify-content: start;
    align-items: center;
    text-align: center;
    stroke: inset 18px;
    color: white;
    & h2{
      font-family: var(--font-primary);
      font-size: var(--TitreMobile);
    }
    & img{
      width: 500px;
      border: 10px white solid;
      height: 300px;
      object-fit: cover;
    }
    & img:hover{
      width:650px;
      border: 10px white solid;
      height: 450px;
      object-fit: cover!important;
      margin: 0!important;
      transition: width 0.3s ease-in-out; /* Ajout de la transition */
    }
    & p:first-of-type{
      text-align:center;
      width: 75%;
    }
}
.grid-col-carousel-description{
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
}
.grid-col-carousel-description-item{
  width: fit-content!important;
  border: 1px solid white;
  border-radius: 50px;
  padding: .5rem;
}
.carousel-item:last-child{
  gap: 2rem;
  // margin-top: -10vh;
  align-items: center;
  display: flex;
  flex-direction: row;
  // width: 30vw;
  gap:1.5rem;
    justify-content: start;
    align-items: center;
    text-align: center;
}
button{
  background-color: white;
  border: none;
  color: none;
}

/* FORMULAIRE*/
.formulaire{
  display: flex;
  justify-content: center;
  width: 100vw;
  height: calc(580px* 1.2);
}
.form{
    background-image: url('./assets/Elements/images/form-desktop.png');
    width: 100%;
    height: 100%;
    padding-top:4%;
    object-fit: cover;
    background-repeat: no-repeat;
    background-position: center top;
    z-index: 3;
    display: grid;
    grid-template-columns: 42vw 50vw;
    grid-template-rows: 1fr;
    grid-column-gap: 20px;
    grid-row-gap: 20px;
}
.formGite{
  background-image: url('./assets/Elements/images/form-desktop-gite.png');
  width: 100%;
  height: 100%;
  padding-top:4%;
  object-fit: cover;
  background-repeat: no-repeat;
  background-position: center top;
  z-index: 3;
  display: grid;
  grid-template-columns: 42vw 50vw;
  grid-template-rows: 1fr;
  grid-column-gap: 20px;
  grid-row-gap: 20px;
}
.formMobile,
.formMobileGite{
display: none;
}

.grid-containerz {
  display: flex;
  flex-direction: column;
  width: 38vw;
  gap: 1rem;
  position: relative;
}
label{
  font-family: var(--font-secondary);
  color: #757575;
  font-weight: 400;
  width: 70vw;
}
input{
  border: 1px solid #BFC0BF;
  border-radius: 7px;
  padding: .5rem;
}
textarea{
  border: 1px solid #BFC0BF;
  border-radius: 7px;
}
input[type=text], input[type=email], textarea {
  width: 78%;
  border: 1px solid #d9d9d9;
  display: block;
  font-size: 14px;
  padding: 10px;
  box-sizing: border-box;
  margin: 0 0 5px;
  font-family: var(--font-secondary);
  color: #3f3c3cd1!important; /* Changez la couleur selon votre préférence */
}
#btn-form{
  background-color: #C5AE4B;
  font-family: var(--font-secondary)!important;
  color: white;
  border: none;
  padding: 10px;
  border-radius: 15px;
  margin-top: 2%;

}
.grid-itemz{
  width: 100%;
}
.grid-itemz-submit {
  grid-column: span 2;
  text-align: center;
}

/*CONTACT*/
.contact{
  padding: 2rem 0 2rem 0;
}
.contact, .contact-item{
  background-color: var(--primary-color);
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  justify-content: center;
  align-items: center;
  & p{
    font-family: var(--font-secondary);
  }
}
.contact-item img{
  width: 1rem;
}

.transports{
  background-color: var(--primary-color);
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 5rem;
  padding: 2rem 0 2rem 0;
}
.transport-items{
  display: flex;
  flex-direction: column;
  justify-content: center;
  & p{
    font-family: var(--font-secondary);
  }
}
.transport-header{
  display: flex;
  flex-wrap: nowrap;  
  justify-content: center;
  align-items: center;
  gap: 1rem;
  height: fit-content;
  & img {
    width: 5rem;
  }
  & p{
    font-size: 1.6rem;
  }

}
.transport-text{
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 1rem;
  & p:first-of-type{
    font-weight: 700;
  }
  & p:last-of-type{
    font-weight: 100;
  }
}
.contact-croquis-parent{
  position:relative;
  height: 30vh;
  background-Color:#335956;
}

#contact-croquis{
  width: 50%;
  height: 100%;
  position: absolute;
  left: 50%;
}

/*RESPONSIVE*/
@media only screen and (max-width : 1440px) {
  .tiret-hp{
    top: 95vh;
  }
  #titre-video{
    top: 80%;
  }
  .background-video video {
    height: 100%;
    width: 100%;
    object-fit: cover;
  }
  .form{
    padding-top: 6%;
    grid-template-columns: 35vw 60vw;
  }
  .grid-containerz {
    width: 70vw;
  }
}
@media only screen and (max-width : 1024px) {
  .ticket{
    top: 30vh;
  }
  .footer-fixed-grid-item {
    display: flex;
    flex-direction: column;
    gap: .5rem;
}
h1, h2{
  font-size: var(--TitreMobile);
  line-height: var(--Titre-lhMobile);
  position: relative;
  text-align: center;
  margin: 0;
}
h3{
  position: relative;
  text-align: center;
  margin: 0;
  font-size: var(--SousTitreMobile);
  line-height: var(--SousTitre-lhMobile);
}
.section{
  position: relative;
  background-color: var(--primary-color);
  padding: 1rem;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  padding-top: 5vh;
}
#titre-video{
  top:84%
}
.emphase{
  font-size: calc(var(--SousTitreMobile)/1.3);
  margin-bottom: 0;
}
.titres{
  font-size: var(--Titre);
}
.grid4 img {
  width: 100%;
}
.grid4-pills-item img {
  width: 1.2em;
  height: auto;
  border: none;
}
.grid4{
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: 1fr;
    grid-column-gap: 20px;
    grid-row-gap: 20px;
}
.transport-text{
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  & p:first-of-type{
    font-size: 1.2rem;
  }
  & p:last-of-type{
    font-size: 1.2rem;
  }
}
.form{
  background-position: left;
  padding-top: 18%;
  grid-template-columns: 35vw 65vw;
  background-size: 1024px;
}
.grid-containerz {
  width: 58vw;
  gap:2px
}
#btn-form{
  margin-top:0
}

}
@media only screen and (max-width : 768px) {
   // Tout en colonne 
  .mobile{
    display:block;
    width: 100%;
  }
  .desktop{
    display: none;
  }
  .navbar{
    position:fixed;
    display: flex;
    align-items: center;
    z-index:6;
    top:0px;
    height: 3rem;
  }
  .navbar img{
    width: 10rem;
    height: auto;
    margin-left : 2rem;
  }
  #croix{
    width: 3rem;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
  }
  .navbar-full-items2 img{
    width: 3rem;
    margin: 0;
  }
  #logo-navbar{
    margin: 0;
  }
  .navbar-full-items{
    margin-bottom: 0;
  }
  .navbar-right{
    font-size: 1rem;
  }
  .grid-container{
    overflow-x: hidden;
    display: flex;
    width: 100vw;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 150px;
  }
  .grid-item{
  width: 100vw;
  height: 60vh;
  flex:auto;
  }
  .grid-item p{
    margin-left: 0!important;
  }
  .grid4, .grid5 {
    display: grid;
    grid-template-columns: 1fr;
    grid-column-gap: 30px;
    grid-row-gap: 30px;
    justify-content: center;
    margin-top: 0!important;
  }
  .grid4-img, .grid4-description{
    position: relative;
    flex-wrap: wrap;
    display: flex;
    justify-content: center;
    align-items: center;
    animation:none;
    width: 90vw;
    text-align: center;
  }
  .grid4-description{
    padding: 3rem;
  }
  .grid4 img{
    width: 100%;
  }
  .grid4-img-col2{
    flex-direction: column;
  }
  .grid4-pills-item img{
      width: 1.2em;
      height: auto;
      border: none;
  }
  .grid-footer {
   display: flex;
   flex-direction: column;
   gap: 10px;
   justify-content: center;
   align-items: center;
  }  
  .grid-footer-logo img{
    width: 20rem;
    justify-content: center;
  }
  .grid-footer-adress{
    align-items: center;
    justify-content: center;
    padding: 0 2rem;
    & p {
      text-align: center;
    }
  }
  .grid-footer-item1{
    display: flex;
    flex-direction: column;
    padding: 0 2rem;
  }
  .footer-fixed-grid{
    padding: 0 2rem;
    & img{
      width: 1.5rem;
    }
    & p{
      font-size: var(--Nav);
    }
  }
  .grid-manade{
    display: flex;
    flex-wrap: wrap;
    gap: .5rem;
    justify-content: center;
  }
  .grid-manade1{
    width: 100vw;
    height: 40vh;
    object-fit: cover;
    margin: 0;
  }
  .grid-manade2{
    width: 100vw;
    height: 40vh;
    object-fit: cover;
    margin: 0;
  }
  .grid-manade-text{
    margin: 0;
  }
  .grid-manade-svg{
    margin-top: 5px;
    width: 8%;
    height: auto;
  }
  #logobec img{
      width: 10rem;
      height: auto;
  }
  #trident{
    margin-left: 70%;
  }
  .TexteNarratif{
    font-size: var(--TexteNarratifMobile);
    line-height: var(--TexteNarratiflhMobile);
  }
.transports{
  background-color: var(--primary-color);
  display: flex;
  flex-direction: column;
  gap: 2rem;
  justify-content: center;
  align-items: center;
}
.transport-items{
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.transport-header{
  gap: 1rem;
  & p{
    font-size: 1.6rem;
  }

}
.transport-text{
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 1rem;
}
.contact-croquis-parent{
  position:relative;
  height: 50vh;
  background-Color:#335956;
}
#contact-croquis{
  width: 100%;
  height: 100%;
  position: absolute;
  left: 30%;
}
.form,
.formGite {
  display: none;
}
.formulaire{
  display: flex;
  justify-content: center;
  height: 100vh;
}
.formMobile{
  background-image: url('./assets/Elements/images/from-mobile.png');
  width: 100%;
  height: 100%;
  z-index: 3;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: repeat(2, 1fr);
  gap: 1rem;
  justify-content: center;
  margin-bottom: 10rem;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}
.formMobileGite{
  background-image: url('./assets/Elements/images/from-mobileGite.png');
  width: 100%;
  height: 100%;
  z-index: 3;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: repeat(2, 1fr);
  gap: 1rem;
  justify-content: center;
  margin-bottom: 10rem;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}
.grid-containerz {
  width: 100vw;
  height: 100vh;
  gap: 1rem;
  justify-content: center;
}
.grid-itemz {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}
label{
  width: 30%;
}
input[type=text], input[type=email], textarea {
  width: 30%;
}
textarea{
  height: 20vh;
}
/* CAROUSEL VERTICAL*/
.grid-col-carousel{
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr;
  width: 100vw;
  height: 100%;
  gap: 10px;
}
.grid-col-carousel-description-item{
width: fit-content!important;
border: 1px solid white;
border-radius: 50px;
padding: .5rem;
}
.carousel-item:last-child{
margin-top: 10px;
justify-content: center;
align-items: center;
width: 100vw;
}
}

@media only screen and (max-width : 425px) {
  .ticket{
    display: none;
  }
  label{
    width: 50%;
  }
  input[type=text], input[type=email], textarea {
    width: 50%;
  }
  textarea{
    height: 20vh;
  }
  .carousel-item img{
    width: 18.75em;
    height: 9.37em;
    border: 5px white solid;
  }
  #fleche{
    width:1rem
  }
}
@media only screen and (max-width : 320px) {
  .navbar-full-items a p{
    font-size: calc(var(--Menu)/1.3) !important;
  }
  .grid-containerz{
    height: 88vh;
  }
  .carousel-item img{
    width: 15em;
    height: 7em;
    border: 5px white solid;
  }
}